import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// import BackgroundImage from 'gatsby-background-image'
import Layout from '../components/Layout/index'
import './styles/apps.scss'
import check from '../images/apps/check.svg'
import Pagina from '../components/Pagina'

// Assets
import appStoreButton from '../images/apps/app-store.svg'
import googleStoreButton from '../images/apps/google-store.svg'

const AppSpani = ({ data }) => {
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Home' },
      { path: '/aplicativo-spani/', name: 'Aplicativo' }
    ]
  }

  return (
    <Layout breadCrumb={breadC} >
      <Pagina pagina={data.pagina}/>
      <section className="container mt-4">
        <div className="row">
          <div className="col-lg-6 p-5">
            <GatsbyImage
              image={data.img.childImageSharp.gatsbyImageData}
              alt="Celulares com aplicativo do Spani"
              width='450'
              height='607' />
          </div>
          <div className="col-lg-6">
            <div className="row d-flex align-items-center h-100">
              <div>
                <div className="col-lg-12">
                  <h2 className="text-black">Aplicativo</h2>
                </div>
                <div className="col-lg-12">
                  <h3 className="mb-4">Vantagens do App Spani</h3>
                </div>
                <ul className="text-dark ml-5">
                  <li className="d-flex mb-4 align-items-center font-openSans-semibold"><img src={check} style={{ width: 46, height: 30 }} alt="Icone de check verde" className="pr-3" /><p>Compre diretamente da sua casa.</p></li>
                  <li className="d-flex mb-4 align-items-center font-openSans-semibold"><img src={check} style={{ width: 46, height: 30 }} alt="Icone de check verde" className="pr-3" /><p>Consulte ofertas.</p></li>
                  <li className="d-flex mb-4 align-items-center font-openSans-semibold"><img src={check} style={{ width: 46, height: 30 }} alt="Icone de check verde" className="pr-3" /><p>Crie lista de compras e muito mais.</p></li>
                </ul>
                <div className="col-lg-12">
                  <p className="font-openSans-semibold">Baixe agora no seu celular:</p>
                </div>
                <div className="d-flex my-4">
                  <div className="col-lg-6">
                    <a href="https://apps.apple.com/br/app/spani-online/id1521663001" target="_blank" without rel="noreferrer">
                      <img
                        src={appStoreButton}
                        className="w-100"
                        alt="Aplicativo na app store"
                      />
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <a href="https://play.google.com/store/apps/details?id=br.com.spanionline.appvendas" target="_blank" without rel="noreferrer">
                      <img
                        src={googleStoreButton}
                        className="w-100"
                        alt="Aplicativo no google play"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default AppSpani

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/aplicativo-spani/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
    desktop {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    mobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 414, height: 310, layout: CONSTRAINED)
        }
      }
    }
  }
  img: file(relativePath: {eq: "apps/screen-apps_1.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 504, height: 461, layout: CONSTRAINED)
    }
  }
}
`
